/* .page {
  width: 21cm;
  min-height: 29.7cm;
  padding: 2cm;
  margin: 1cm auto;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
} */

.page {
  display: block;
  width: 21cm;
  width: 100%;
  min-height: 37cm;
  padding: 0.5cm;
  /* margin: 1cm; */
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  /*background-image: url("https://bansgadhimun.ideabreed.net/assets/images/nepalikagaz.jpg");*/
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  position: relative;
}
/* 
.page {
  position: relative;
  width: 21cm;
  height: 29.7cm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  position: relative;
} */

.modal .page {
  width: 50%;
}
/* .content {
    padding: 1cm;
    height: 256mm;
    outline: 2cm #ffeaea solid;
  } */
.image-content {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.content-image {
  max-width: 15cm;
  max-height: 250mm;
}

.input-style {
  outline: none;
  border: none;
  width: fit-content;
}

nav {
  background: #4a7fc7;
  color: white;
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
}

.languageBtn {
  margin: 0 0.5rem;
  padding: 0.2rem 1rem;
  text-transform: capitalize;
  font-weight: 600;
  cursor: pointer;
  transition: transform 150ms;
}
.languageBtn:hover {
  transform: scale(1.05);
}
.languageBtn:active {
  transform: scale(0.9);
}

.modal > .fullscreen-modal {
  margin: 0;
  max-width: 100%;
}

.paragraph {
  font-size: 16px;
}

.dateInput {
  border: none;
  width: 11ch;
  font-weight: 800;
}

.table-input {
  width: 100%;
  height: 75%;
  border: none;
}

.table-input:focus {
  outline: 1px solid black;
}

.logo {
  width: 80px;
}
.logo-camp {
  width: 180px;
}
.title {
  color: red;
}
.title h5 {
  margin-bottom: 5px;
  font-size: 22px;
}
.title h3 {
  margin-bottom: 5px;
  font-size: 32px;
  font-weight: 600;
}

.content-paragraph {
  text-indent: 60px;
  line-height: 30px;
  text-align: justify;
}

.page-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  font-weight: 300;
}

hr {
  border-top: solid 3px rgb(92, 91, 91) !important;
}
